// import React from "react";

// const ThankYou = () => {
//   return (
//     <div className="min-h-screen flex flex-col items-center justify-center text-center">
//       <header className="site-header">
//         <h1 className="site-header__title text-4xl font-bold mb-8 font-space-grotesk">
//           THANK YOU!
//         </h1>
//       </header>

//       <div className="main-content">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           className="h-16 w-16 text-green-700 mx-auto mb-8"
//           fill="none"
//           viewBox="0 0 24 24"
//           stroke="currentColor"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="4"
//             d="M5 13l4 4L19 7"
//           />
//         </svg>

//         <p className="main-content__body text-sm  font-space-grotesk leading-relaxed max-w-lg mx-auto">
//           Your mutual divorce form has been successfully submitted.

//           <br />
//          If you have any questions or need further assistance, feel free to contact
//           us.
//         </p>

//         <p className="mt-6 text-sm font-space-grotesk">
//           We appreciate your cooperation during this process.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ThankYou;

import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo_lw.webp";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();

  const [logoImage, setLogoImage] = useState(
    "https://law-wheel.b-cdn.net/image/logo_law.webp"
  );



  useEffect(() => {
    const img = new Image();
    img.src = "https://law-wheel.b-cdn.net/image/logo_law.webp";
    img.onload = () =>
      setLogoImage("https://law-wheel.b-cdn.net/image/logo_law.webp");
    img.onerror = () => setLogoImage(logo);
  }, []);

  const goToHome = () => {
    navigate("/");
  };
  return (
    // <section
    //   className="wrapper bg-repeat bg-fixed bg-image section-frame xl:mx-[1.5rem] xl:rounded-[1rem] lg:mx-[1.5rem] lg:rounded-[1rem] md:mx-[1.5rem] md:rounded-[1rem]"
    //   style={{
    //     backgroundImage: `url(${"https://law-wheel.b-cdn.net/image/bg3.jpg"})`,
    //   }}
    // >
    //   <div className="container pt-24 xl:pt-20 lg:pt-20 md:pt-28 xl:pb-20 lg:pb-20 md:pb-20">
    //     <div className="min-h-96 flex flex-col items-center justify-center text-center">
    //       <header className="site-header">
    //         <h1 className="site-header__title text-4xl font-bold mb-8 font-space-grotesk text-white">
    //           THANK YOU!
    //         </h1>
    //       </header>

    //       <div className="main-content">
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="h-16 w-16 text-green-700 mx-auto mb-8"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="4"
    //             d="M5 13l4 4L19 7"
    //           />
    //         </svg>

    //         <p className="main-content__body text-sm text-white font-space-grotesk leading-relaxed max-w-lg mx-auto">
    //           Your mutual divorce form has been successfully submitted.
    //           <br />
    //           If you have any questions or need further assistance, feel free to
    //           contact us.
    //         </p>

    //         <p className="mt-6 text-sm text-white font-space-grotesk">
    //           We appreciate your cooperation during this process.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    // <div style={{margin:'10px'}}>

    //   <section
    //   className="wrapper bg-repeat bg-fixed bg-image section-frame xl:mx-[1.5rem] xl:rounded-[1rem] lg:mx-[1.5rem] lg:rounded-[1rem] md:mx-[1.5rem] md:rounded-[1rem] relative"
    //   style={{
    //     backgroundImage: `url(${"https://law-wheel.b-cdn.net/image/bg3.jpg"})`,
    //   }}
    // >
    //   <div className="container pt-24 pb-24 xl:pt-20 lg:pt-20 md:pt-28 xl:pb-20 lg:pb-20 md:pb-20 flex justify-center items-center min-h-screen relative">
    //     {/* Background Logo Image */}
    //     <img
    //       src={logoImage}
    //       alt="Logo"
    //       className="absolute top-0 left-0 w-full h-full object-cover opacity-30"
    //     />

    //     <div className="min-h-96 flex flex-col items-center justify-center text-center my-auto relative z-10">
    //       <header className="site-header">
    //         <h1 className="site-header__title text-4xl font-bold mb-8 font-space-grotesk text-white">
    //           THANK YOU!
    //         </h1>
    //       </header>

    //       <div className="main-content">
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           className="h-16 w-16 text-green-700 mx-auto mb-8"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke="currentColor"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="4"
    //             d="M5 13l4 4L19 7"
    //           />
    //         </svg>

    //         <p className="main-content__body text-sm text-white font-space-grotesk leading-relaxed max-w-lg mx-auto">
    //           Your mutual divorce form has been successfully submitted.
    //           <br />
    //           If you have any questions or need further assistance, feel free to
    //           contact us.
    //         </p>

    //         <p className="mt-6 text-sm text-white font-space-grotesk">
    //           We appreciate your cooperation during this process.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    // </div>

    <section
      className="wrapper bg-repeat bg-fixed bg-image section-frame xl:mx-[1.5rem] xl:rounded-[1rem] lg:mx-[1.5rem] lg:rounded-[1rem] md:mx-[1.5rem] md:rounded-[1rem] relative"
      // style={{
      //   backgroundImage: `url(${"https://law-wheel.b-cdn.net/image/bg3.jpg"})`,
      // }}
    >
      <div className="container pt-24 pb-24 xl:pt-20 lg:pt-20 md:pt-28 xl:pb-20 lg:pb-20 md:pb-20 flex justify-center items-center min-h-screen relative">
      <img
          src={logoImage}
          alt="Logo"
          className="absolute top-50 left-50  xl:w-1/2 min-h-56 lg:w-1/2 md:w-full object-cover opacity-50 sm:w-full min-h-96"
          style={{ objectFit: "cover" }}
        />

        <div className="min-h-96 flex flex-col items-center justify-center text-center my-auto relative z-10">
          <header className="site-header">
            <h1 className="site-header__title text-6xl font-bold mb-8 font-space-grotesk text-black">
              THANK YOU!
            </h1>
          </header>

          <div className="main-content">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              className="h-16 w-16 text-green-700 mx-auto mb-8"
              viewBox="0 0 117.72 117.72"
              style={{ enableBackground: "new 0 0 117.72 117.72" }}
              xmlSpace="preserve"
            >
              <style type="text/css">{`.st0 { fill: #01A601; }`}</style>
              <g>
                <path
                  className="st0"
                  d="M58.86,0c9.13,0,17.77,2.08,25.49,5.79c-3.16,2.5-6.09,4.9-8.82,7.21c-5.2-1.89-10.81-2.92-16.66-2.92
          c-13.47,0-25.67,5.46-34.49,14.29c-8.83,8.83-14.29,21.02-14.29,34.49c0,13.47,5.46,25.66,14.29,34.49
          c8.83,8.83,21.02,14.29,34.49,14.29s25.67-5.46,34.49-14.29c8.83-8.83,14.29-21.02,14.29-34.49c0-3.2-0.31-6.34-0.9-9.37
          c2.53-3.3,5.12-6.59,7.77-9.85c2.08,6.02,3.21,12.49,3.21,19.22c0,16.25-6.59,30.97-17.24,41.62
          c-10.65,10.65-25.37,17.24-41.62,17.24c-16.25,0-30.97-6.59-41.62-17.24C6.59,89.83,0,75.11,0,58.86
          c0-16.25,6.59-30.97,17.24-41.62S42.61,0,58.86,0L58.86,0z M31.44,49.19L45.8,49l1.07,0.28c2.9,1.67,5.63,3.58,8.18,5.74
          c1.84,1.56,3.6,3.26,5.27,5.1c5.15-8.29,10.64-15.9,16.44-22.9c6.35-7.67,13.09-14.63,20.17-20.98l1.4-0.54H114l-3.16,3.51
          C101.13,30,92.32,41.15,84.36,52.65C76.4,64.16,69.28,76.04,62.95,88.27l-1.97,3.8l-1.81-3.87c-3.34-7.17-7.34-13.75-12.11-19.63
          c-4.77-5.88-10.32-11.1-16.79-15.54L31.44,49.19L31.44,49.19z"
                />
              </g>
            </svg>

         
                <p className="main-content__body text-sm text-black font-space-grotesk leading-relaxed max-w-lg mx-auto">
                  Thank You for Reaching Out! Your request has been successfully
                  received. One of our legal experts will contact you shortly to
                  discuss your consultation and assist you with your legal
                  matters.
                </p>

                <p className="mt-6 text-sm text-black font-space-grotesk">
                  If you need immediate assistance, feel free to call us <br />at +91 
                  8130603888, Toll free Number- 1800 571 1413.
                </p>
           
            <button
              onClick={goToHome}
              className="flex items-center justify-center btn font-space-grotesk btn-primary text-white !bg-[#3f78e0] border-[#3f78e0] hover:text-white hover:bg-[#3f78e0] hover:border-[#3f78e0] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#3f78e0] active:border-[#3f78e0] disabled:text-white disabled:bg-[#3f78e0] disabled:border-[#3f78e0] btn-send !mb-3 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)] gradient-button"
            >
              Go to Home
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
